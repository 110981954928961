import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { validatePostcode } from './validation/validatePostcode';
import './general.css';
import Layout from './components/Layout';
import DeleteAccountButton from './components/DeleteButton';

type UserDetails = {
  Username: string;
  Email: string;
  Postcode: string;
};

function getCookie(name: string): string | null {
  if (name === undefined || name === '') {
    // You can handle the case where name is undefined or empty if needed
    return null;
  }

  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return decodeURIComponent(parts.pop()?.split(';').shift() || '');
  }
  return null;
}

const AccountDetailsPage: React.FC = () => {
  const [postcode, setPostcode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  const [userDetails, setUserDetails] = useState<UserDetails>({
    Username: '',
    Email: '',
    Postcode: '',
  });
  const navigate = useNavigate();

  const apiUpdatePostcodeURL = `https://sportsphere-backend.fly.dev:443/updatepostcode`;
  const apiGetUserDetailsURL = `https://sportsphere-backend.fly.dev:443/getuserdetails`;

  const handlePostcodeChange = async (e: React.FormEvent) => {
    e.preventDefault();

    const token = getCookie('token');
    if (!token) {
      console.error('No token found');
    }

    const postcodeError = validatePostcode(postcode);

    setError(postcodeError);

    setLoading(true); // Set loading state
    setError('');
    setSuccess('');

    try {
      // Call your backend API to reset the password
      axios.post(apiUpdatePostcodeURL, {
        Postcode: postcode,
      },
        { withCredentials: true });

      setSuccess('Postcode updated successfully!')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error.response?.data?.message || 'An error occurred while updating the postcode');
      } else {
        setError('An unknown error occurred');
      }
    } finally {
      setLoading(false); // End loading state
    }
  };

  const handleRedirectToEventSearch = () => {
    navigate('/return-results')
  };

  useEffect(() => {
    axios.get<UserDetails>(apiGetUserDetailsURL, {
      withCredentials: true,
    })
      .then((response) => {
        setUserDetails(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });
  }, [apiGetUserDetailsURL]);

  return (
    <>
      <div className="container mt-5">
        <div className="redirect-button-container">
          <button className="btn-redirect" onClick={handleRedirectToEventSearch}>Back to Search Events</button>
        </div>
        <Layout isLoginPage={false} />
        <div className="form-container mt-5">
          <h4>User Profile</h4>
          {userDetails ? (
            <div>
              <p><strong>Username:</strong> {userDetails.Username}</p>
              <p><strong>Email:</strong> {userDetails.Email}</p>
              <p><strong>Postcode:</strong> {userDetails.Postcode}</p>
            </div>
          ) : (
            <p>Loading user data...</p>
          )}
          <h4>Update Postcode</h4>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {success && <p style={{ color: 'green' }}>{success}</p>}

          <form onSubmit={handlePostcodeChange}>
            <label>
              New Postcode:
              <input
                type="postcode"
                value={postcode}
                onChange={(e) => setPostcode(e.target.value)}
                disabled={loading} // Disable input while loading
              />
            </label>
            <br /><br />
            <button className="btn-redirect" type="submit" disabled={loading}>
              {loading ? 'Updating...' : 'Update Postcode'}
            </button>
            <br /><br />
          </form>
          <DeleteAccountButton UserDetails={userDetails} />
        </div>
      </div>
    </>
  );
};

export default AccountDetailsPage;
