// LoginPage.tsx
import React, { useState, ChangeEvent, FormEvent } from 'react';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import StravaLogin from './components/stravaAuthenticate.tsx';
import Layout from './components/Layout';
import './loginPage.css';
import './general.css';

interface FormData {
  Username: string;
  Password: string;
}

const LoginPage: React.FC = () => {
  const apiURL = `https://sportsphere-backend.fly.dev:443/login`
  const navigate = useNavigate(); // Access the history object
  const [formData, setFormData] = useState<FormData>({
    Username: '',
    Password: '',
  });
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // // Function to set a cookie
  // function setCookie(name: string, value: string, days: number) {
  //   const expires = days ? "; expires=" + new Date(Date.now() + days * 864e5).toUTCString() : "";
  //   document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/; Secure; SameSite=Strict`;
  // }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await axios.post(apiURL, formData, { withCredentials: true });
      console.log('Response Data:', response.data);

      // const token = response.data;
      // setCookie('token', token, 1);

      // Handle success, e.g., show a success message or redirect to another page
      console.log('User logged in successfully', response.data);

      // Redirect to the login page
      navigate('/return-results');
    } catch (error) {
      const errMessage = (error as Error).message;

      setErrorMessage('Invalid username/email or password');
      // Handle error, e.g., show an error message to the user
      console.error('Login failed:', errMessage);
    }
  };

  return (
    <>
      <div className="text-center form-container" style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
        <Layout isLoginPage={true} />
        <h2 className="mb-4">Login</h2>
        <Form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '350px' }}>
          <Form.Group controlId="Username" className="mb-3">
            <Form.Label>Username/Email:</Form.Label>
            <Form.Control
              type="text"
              name="Username"
              value={formData.Username}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="Password" className="mb-3">
            <Form.Label>Password:</Form.Label>
            <Form.Control
              type="Password"
              name="Password"
              value={formData.Password}
              onChange={handleChange}
              required
            />
          </Form.Group>
          {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>} <br /><br />

          <Button variant="primary" className="btn-redirect" type="submit">
            Login
          </Button><br />

          <StravaLogin />

          <Link className="reset-link" to="/reset-password-email">
            Reset my Password
          </Link><br /><br />

          <Link to="/create-user" className="btn-redirect">
            Create User
          </Link>
        </Form>
      </div>
    </>
  );
};

export default LoginPage;
