import React from 'react';
import './StravaAuthenticate.css';

const STRAVA_CLIENT_ID = '138690';
const STRAVA_REDIRECT_URI = 'https://sportsphere-backend.fly.dev:443/return-results'; // Replace with your redirect URI

const StravaLogin: React.FC = () => {
  const handleLogin = () => {
    const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    const authUrl = `https://www.strava.com/oauth/${isMobile ? 'mobile' : ''}/authorize?client_id=${STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${STRAVA_REDIRECT_URI}&scope=read`;
    console.log(authUrl);
    window.location.href = authUrl;
  };

  return (
    <div className="strava-button-container">
      <button className="strava-button" onClick={handleLogin}>Log in with Strava</button>
    </div>
  );
};

export default StravaLogin;
