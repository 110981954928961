// CreateUser.tsx

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './general.css';
import { validateEmail } from './validation/validateEmail';
import Layout from './components/Layout';

interface FormData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const apiForgotPasswordURL = `https://sportsphere-backend.fly.dev:443/forgotpassword`
  const navigate = useNavigate(); // Access the history object
  const [formData, setFormData] = useState<FormData>({
    email: '',
  });
  const [message, setMessage] = useState('');

  const validateForm = () => {
    const emailError = validateEmail(formData.email, true);

    return !emailError;
  };

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        axios.post(apiForgotPasswordURL, { email: formData.email }, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // console.log(forgotPasswordResponse.data);
        setMessage('An email will be sent to your email address if it exists');
      } catch (error) {
        // console.error('error sending forgot password email for this user', error);
        setMessage('An email will be sent to your email address if it exists');
      }
    } else {
      console.log('Email form is invalid. Fix errors.');
      setMessage('The email format provided is invalid')
    }
  };

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <>
      <div className="redirect-button-container">
        <button
          onClick={handleLoginRedirect}
          className="btn-redirect">
          Back to Login Page
        </button>
      </div>
      <div
        className="text-center form-container"
        style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '20px' }}
      >
        <Layout isLoginPage={true} />
        <h4 className="mb-4">Reset Password</h4>

        <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '350px' }}>

          <div className="mb-3 space-out" style={{ paddingBottom: '20px' }}>
            <label htmlFor="email" style={{ fontSize: '20px' }}>
              Email:
            </label>
            <br />
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter Email Address"
              required
              className="col-12"
              style={{ fontSize: '14px', paddingRight: '30px' }}
            />
            {message && <span style={{ color: 'green' }}>{message}</span>}
          </div>

          <button
            type="submit"
            className="btn-redirect"
          >
            Send Password Reset Email
          </button>
        </form>
      </div >
    </>
  );
};

export default ForgotPassword;
