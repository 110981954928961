// CreateUser.tsx

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import './createUser.css'; // Import the custom CSS file for styling
import './general.css';
import { validateEmail } from './validation/validateEmail';
import { validateUsername } from './validation/validateUsername';
import { validatePassword } from './validation/validatePassword';
import { validatePostcode } from './validation/validatePostcode';
import Layout from './components/Layout';

interface FormData {
  username: string;
  password: string;
  email: string;
  postcode: string;
  recaptchaToken: string;
}

interface UsernameEmailUniqueResponse {
  UsernameUnique: boolean;
  EmailUnique: boolean;
}

interface Errors {
  username?: string;
  password?: string;
  email?: string;
  postcode?: string;
}

const CreateUser: React.FC = () => {
  const apiCreateUserURL = `https://sportsphere-backend.fly.dev:443/createuser`
  const apiCheckUsernameEmailUnique = `https://sportsphere-backend.fly.dev:443/checkusernameemailunique`
  const navigate = useNavigate(); // Access the history object
  const [formData, setFormData] = useState<FormData>({
    username: '',
    password: '',
    email: '',
    postcode: '',
    recaptchaToken: '',
  });

  const [retypePassword, setRetypePassword] = useState('');
  const [errors, setErrors] = useState<Errors>({});

  const [recaptchaToken, setRecaptchaToken] = useState<string>('');

  const validateForm = (isEmailUnique: boolean, isUsernameUnique: boolean) => {
    const emailError = validateEmail(formData.email, isEmailUnique);
    const usernameError = validateUsername(formData.username, isUsernameUnique);
    const passwordError = validatePassword(formData.password, retypePassword);
    const postcodeError = validatePostcode(formData.postcode);

    setErrors({
      email: emailError,
      username: usernameError,
      password: passwordError,
      postcode: postcodeError,
    });

    return !emailError && !usernameError && !passwordError;
  };

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: name === 'postcode' ? value.toUpperCase() : value
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (recaptchaToken.length === 0) {
      alert('Please complete the reCAPTCHA');
      return;
    }

    formData.recaptchaToken = recaptchaToken;

    setRecaptchaToken('');

    const usernameEmailUniqueResponse = await axios.post<UsernameEmailUniqueResponse>(apiCheckUsernameEmailUnique, { email: formData.email, username: formData.username }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const { UsernameUnique, EmailUnique } = usernameEmailUniqueResponse.data

    console.log(EmailUnique, UsernameUnique)

    if (validateForm(UsernameUnique, EmailUnique)) {
      try {
        const response = await axios.post(apiCreateUserURL, formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // Handle success, e.g., show a success message or redirect to another page
        console.log('User created successfully', response.data);

        // Redirect to the login page
        navigate('/login');
      } catch (error) {
        // Handle errors, e.g., display an error message
        console.error('Error creating user', error);
      }
      console.log('Form is valid. Submitting...');
    } else {
      console.log('Form is invalid. Fix errors.', errors);
    }
  };

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  const handleRecaptchaChange = (token: string | null) => {
    if (token !== null) {
      setRecaptchaToken(token);
    }
  };

  return (
    <>
      <div className="redirect-button-container">
        <button
          onClick={handleLoginRedirect}
          className="btn-redirect"
        >
          Back to Login
        </button>
      </div>
      <div
        className="text-center form-container"
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '20px',
        }}
      >
        <Layout isLoginPage={true} />
        <h1 className="mb-4">Create User</h1>

        <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '350px' }}>
          <div className="mb-3 space-out">
            <label htmlFor="username" style={{ fontSize: '20px' }}>
              Username:
            </label>
            <br />
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              placeholder="Enter Username"
              required
              className="col-12"
              style={{ fontSize: '14px', paddingRight: '30px' }}
            />
            {errors.username && <span style={{ color: 'red' }}>{errors.username}</span>}
          </div>

          <div className="mb-3 space-out">
            <label htmlFor="password" style={{ fontSize: '20px' }}>
              Password:
            </label>
            <br />
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Enter Password"
              required
              className="col-12"
              style={{ fontSize: '14px', paddingRight: '30px' }}
            />
            {errors.password && <span style={{ color: 'red' }}>{errors.password}</span>}
          </div>

          <div className="mb-3">
            <label htmlFor="retypePassword" style={{ fontSize: '20px' }}>
              Retype Password:
            </label>
            <br />
            <input
              type="password"
              id="retypePassword"
              name="retypePassword"
              value={retypePassword}
              onChange={(e) => setRetypePassword(e.target.value)}
              placeholder="Enter Password again"
              required
              className="col-12"
              style={{ fontSize: '14px', paddingRight: '30px' }}
            />
          </div>

          <div className="mb-3 space-out" style={{ paddingBottom: '20px' }}>
            <label htmlFor="email" style={{ fontSize: '20px' }}>
              Email:
            </label>
            <br />
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter Email Address"
              required
              className="col-12"
              style={{ fontSize: '14px', paddingRight: '30px' }}
            />
            {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
          </div>

          <div className="mb-3" style={{ paddingBottom: '20px' }}>
            <label htmlFor="postcode" style={{ fontSize: '20px' }}>
              Postcode:
            </label>
            <br />
            <input
              type="postcode"
              id="postcode"
              name="postcode"
              value={formData.postcode}
              onChange={handleInputChange}
              placeholder="Enter Your Postcode"
              required
              className="col-12"
              style={{ fontSize: '14px', paddingRight: '30px' }}
            />
            {errors.postcode && <span style={{ color: 'red' }}>{errors.postcode}</span>}
          </div>

          <ReCAPTCHA
            sitekey="6Lcjp2wqAAAAAKotkFYe_qMV0UKWRjGjkNRKvDR6"
            onChange={handleRecaptchaChange}
          /><br/>

          <button
            type="submit"
            className="btn-redirect"
          >
            Create User
          </button>
        </form>
      </div>
    </>
  );
};

export default CreateUser;
